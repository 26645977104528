


<script>

import {useAccountStore} from '/src/stores/account'

export default {

    data() {
        return {

        accountStore : useAccountStore()



        }



    }
 
}
</script>
<template>
<b-card header-class="bg-transparent border-warning" class="border border-warning mb-0">

    <h5 class="my-0 text-warning">
        <i class="fa fa-solid fa-coins"></i>
    </h5><br>
    <p class="card-title mt-0 text-warning">     {{ Number(accountStore.chips).toLocaleString() }}</p>
</b-card>
</template>
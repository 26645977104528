import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
import { useEventStore } from "./events";
import { usePlayerStore } from "./player";
import { useAccountStore } from "./account";
import { TimeScale } from "chart.js";
const toast = useToast();

export const useOperatorStore = defineStore({
  id: "operator",

  state: () => ({
    eventStore: useEventStore(),
    playerStore: usePlayerStore(),
    accountStore: useAccountStore(),
  }),

  actions: {
    goToRound(gameType, eventId) {
      Swal.fire({
        title: "Skip to what round?",

        icon: "question",
        toast: true,
        html: `
        
        <input type="number" id="skipRound" class="form-control" min='1' max='99' value = 0 oninput="this.value = 
        !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"/>
        
        
        
        
        
        
        `,
        inputValue: 1,
        iconColor: "white",
        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Send",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let skipRound = document.getElementById('skipRound').value
          return axios
            .post(
              `/api/admin/event/${this.eventStore.eventDetails.gameType}/${this.eventStore.eventDetails.eventId}/round/`,
              {
                roundEvent: this.eventStore.eventDetails.eventId,
                operator: this.accountStore.username,
                roundNumber: skipRound,
                status: "closed",
              }
            )
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          let skipRound = document.getElementById('skipRound').value

          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Skipped to round ${skipRound}! `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: `Insufficient Chips!  `,
          });
        }
      });
    },

    declareWinner(choice) {
      Swal.fire({
        title: "Declare " + choice + " as the winner?",
        icon: "warning",
        text: "This action cannot be undone!",
        iconColor: "#F46A6A",
        background: "#2A3042",
        color: "white",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              "/api/admin/event/" +
                this.eventStore.eventDetails.gameType +
                "/" +
                this.eventStore.eventDetails.eventId +
                "/round/winner/",
              { roundWinner: choice }
            )
            .then((response) => {});
        }
      });
    },

    nextFight() {
      Swal.fire({
        title: "Proceed to next fight?",
        text: "This action cannot be undone!",
        icon: "warning",
        iconColor: "#F46A6A",
        background: "#2A3042",
        color: "white",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              "/api/admin/event/" +
                this.eventStore.eventDetails.gameType +
                "/" +
                this.eventStore.eventDetails.eventId +
                "/round/next/",
              {
                roundEvent: this.eventStore.eventDetails.eventId,
                operator: this.accountStore.username,
                roundNumber: this.eventStore.currentRound.roundNumber + 1,
                status: "closed",
              }
            )
            .then((response) => {
              console.log(response.data);

              this.eventStore.getPublicRounds();

              toast.info("Next fight created!", {
                position: "top-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: "mdi mdi-48 mdi-fencing",
                rtl: false,
              });
            });
        }
      });
    },

    // refreshUsers() {
    //   Swal.fire({
    //     title: ("refresh players?"),
    //     text: "This action cannot be undone!",
    //     icon: "warning",
    //     iconColor: "#F46A6A",
    //     background: "#2A3042",
    //     color: "white",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: "Yes"
    //   }).then(result => {
    //     if (result.value) {
    //       console.log(result)
    //       toast.info("players refreshed!",
    //         {
    //           position: "top-center",
    //           timeout: 5000,
    //           closeOnClick: true,
    //           pauseOnFocusLoss: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           draggablePercent: 1,
    //           showCloseButtonOnHover: false,
    //           hideProgressBar: true,
    //           closeButton: "button",
    //           icon: "mdi mdi-48 mdi-fencing",
    //           rtl: false,
    //         },
    //         location.reload()
    //       );
    //     }
    //   });
    // },

    roundStatus(status) {
      Swal.fire({
        title: "Are you sure you want to " + status + " the bets?",

        // text: "This action will be applied for everyone.",
        icon: "warning",
        iconColor: "#F46A6A",
        background: "#2A3042",
        color: "white",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.value) {
          axios
            .patch(
              "/api/admin/event/" +
                this.eventStore.eventDetails.gameType +
                "/" +
                this.eventStore.eventDetails.eventId +
                "/round/" +
                this.eventStore.currentRound.roundId +
                "/",
              { status: status }
            )
            .then((response) => {
              console.log(response.data);
            });
        }
      });
    },
  },

  getters: {},
});

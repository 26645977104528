
<script>

import { SimpleBar } from 'simplebar-vue3';
import {useEventStore} from '/src/stores/events'

export default {

 components: {SimpleBar},
 data() {
    return {
      eventStore: useEventStore()
    }
 },
}
</script>



<template>
<div class="row" v-if="eventStore.eventDetails.gameType == 'sabong'">
    <b-card header-class="bg-transparent" class="border border-info" style="min-height:100px; min-width: 100px;">
    
            <h5 class="my-0 text-info text-center">
                Fight History
            </h5>
       
            <SimpleBar style="max-height: 100px;">
  <div class="d-flex flex-row bd-highlight mb-2 flex-wrap">
  <div class="p-2 bd-highlight" v-for="fight in eventStore.publicRounds">
   
    
    <button class="btn btn-outline-primary rounded-circle font-size-11"  style="width: 100%;" v-if="fight.roundWinner=='wala'">{{fight.roundNumber}}</button>
        <button class="btn btn-outline-danger rounded-circle font-size-11" style="width: 100%;" v-if="fight.roundWinner=='meron'">{{fight.roundNumber}}</button>
            <button class="btn btn-outline-success rounded-circle font-size-11" style="width: 100%;" v-if="fight.roundWinner=='draw'">{{fight.roundNumber}}</button>
                <button class="btn btn-outline-dark rounded-circle font-size-11"  style="width: 100%;"  v-if="fight.status=='cancelled'">{{fight.roundNumber}}</button>


</div>
</div>
</SimpleBar>
   
    
    </b-card>
</div>


<div v-if="eventStore.eventDetails.gameType =='color-game'">
        <div class="card border border-info" style="min-height: 300px;">
            <div class="card-body">
                <h4 class="card-title text-info">Round History</h4>

             

                <div class="">
                    <SimpleBar style="max-height: 200px;">
                        <table class="table table-striped align-middle mb-0 table-sm table-responsive text-center font-size-14">
                            
                            <tbody>


                                <tr v-for="round in eventStore.publicRounds">


                                    <td>{{ round.roundNumber }}</td>


                                 
                                    
                                    <td v-for="winner in round.roundWinner" class="text-black">
                                    <button type="button" class="btn btn-danger"  style="width: 80px;" v-if="winner=='red'">RED</button>
                                    <button type="button" class="btn text-dark" style="width: 80px; background-color:lightslategray;" v-if="winner=='green'">GRAY</button>
                                    <button type="button" class="btn btn-primary" style="width: 80px;" v-if="winner=='blue'">BLUE</button>
                                    <button type="button" class="btn btn-warning " style="width: 80px;" v-if="winner=='yellow'">YELLOW</button>
                                    <button type="button" class="btn btn-dark"  style="width: 80px;" v-if="winner=='white'">WHITE</button>
                                    <button type="button" class="btn btn-pink" style="width: 80px;" v-if="winner=='pink'">PINK</button>
                                    
                                    </td>
                                  

                                    
                            
                                

                                </tr>
                            </tbody>
                        </table>
                    </SimpleBar>
                </div>
            </div>
        </div>

    </div>




</template>
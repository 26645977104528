



<script>

import { useEventStore } from '/src/stores/events'
import { usePlayerStore } from '/src/stores/player'
import { useAccountStore } from '/src/stores/account'
import { useColorGameStore } from '../../stores/colorGame'
import { useSabongStore } from '../../stores/sabong'


export default {




  data() {

    return {
      eventStore: useEventStore(),
      playerStore: usePlayerStore(),
      accountStore: useAccountStore(),
      colorGameStore: useColorGameStore(),
      sabongStore: useSabongStore()
   
    




    };


  },

  mounted(){



  },


  methods: {

   



  }



}




</script>




<template>

    <div class="text-center">


      <div class="row d-flex justify-content-center" v-if="eventStore.eventDetails.gameType=='sabong'">

        <div class="card border border-info">
       
          <div class="card-body ">

            <div class="row">
              <table class="table table-striped">
                <thead class="text-center">
                  <tr>
                    <th class="text-danger"></th>
                    <th class="text-danger">Meron</th>
                    <th class="text-primary">Wala</th>
                    <th class="text-success">Draw</th>
                  

                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr>
                    <th class="text-dark">Bet</th>
                    <td class="text-danger">{{Number(playerStore.meronBet).toLocaleString()}}</td>
                    <td class="text-primary">{{Number(playerStore.walaBet).toLocaleString()}}</td>
                    <td class="text-success">{{Number(playerStore.drawBet).toLocaleString()}}</td>
                  

                  </tr>
                  <tr>
                    <th class="text-dark">Win</th>
<td class="text-danger">{{Number(sabongStore.winning.meron).toLocaleString()}}</td>
<td class="text-primary">{{Number(sabongStore.winning.wala).toLocaleString()}}</td>
<td class="text-success">{{Number(sabongStore.winning.draw).toLocaleString()}}</td>


</tr>


                </tbody>
              </table>
            </div>

         

          </div>
        </div>

    
    

      </div>

      <div class="row d-flex justify-content-center" v-if="eventStore.eventDetails.gameType=='color-game'">

<div class="card border border-info ">
  <div class="card-header bg-transparent">
      <h5 class="my-0 text-info">Your Bets</h5>

    </div>
  <div class="card-body ">

    <div class="">
      <table class="table">
        <thead class="">
          <tr class="">

            <th class="text-danger">{{Number(colorGameStore.bets.red).toLocaleString()}}</th>
            <th class="" style="color:lightslategrey">{{Number(colorGameStore.bets.green).toLocaleString()}}</th>
            <th class="text-primary">{{Number(colorGameStore.bets.blue).toLocaleString()}}</th>
            <th class="text-warning">{{Number(colorGameStore.bets.yellow).toLocaleString()}}</th>
            <th class="text-dark">{{Number(colorGameStore.bets.white).toLocaleString()}}</th>
            <th class="text-pink">{{Number(colorGameStore.bets.pink).toLocaleString()}}</th>
            


          </tr>
        </thead>
       

       
      </table>
    </div>

    <h5 class="my-0 text-warning">
      <i class="fa fa-solid fa-coins"></i>
    </h5><br>
    <h5 class="card-title mt-0 text-warning"> {{ Number(accountStore.chips).toLocaleString() }} CHIPS</h5>

  </div>
</div>




</div>

    </div>







</template>

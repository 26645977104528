



<script>

import { useEventStore } from '/src/stores/events'
import { usePlayerStore } from '/src/stores/player'
import { useAccountStore } from '/src/stores/account'
import { useColorGameStore } from '../../stores/colorGame'
import { useSabongStore } from '../../stores/sabong'


export default {




  data() {

    return {
      eventStore: useEventStore(),
      playerStore: usePlayerStore(),
      accountStore: useAccountStore(),
      colorGameStore: useColorGameStore(),
      sabongStore: useSabongStore()
   
    




    };


  },

  mounted(){



  },


  methods: {

   



  }



}




</script>




<template>

<!-- sabong -->
    <div class="progress mb-3" style="height: 40px;" v-if="this.eventStore.eventDetails.gameType=='sabong'">
      <div class="progress-bar  progress-bar-animated bg-danger" role="progressbar" aria-valuenow="100"
        aria-valuemin="100" :style="{ height: '55px', width: this.playerStore.meronBar + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{ Number(this.eventStore.currentRound.meronTotal).toLocaleString() }}
         </p>

      </div>
      <div class="progress-bar  progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0"
        :style="{ height: '55px', width: this.playerStore.walaBar + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{Number(this.eventStore.currentRound.walaTotal).toLocaleString() }}
      
        </p>
      </div>
    </div>








    <!-- color game -->

    <div class="progress mb-3 col-md-10" style="height: 40px;" v-if="this.eventStore.eventDetails.gameType=='color-game'">
      <div class="progress-bar  progress-bar-animated bg-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0"
        :style="{ height: '55px', width: this.colorGameStore.bars.red + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{Number(this.eventStore.currentRound.redTotal).toLocaleString() }}
        </p>
      </div>
      <div class="progress-bar  progress-bar-animated" style="background-color:lightslategray" role="progressbar" aria-valuenow="50" aria-valuemin="0"
        :style="{ height: '55px', width: this.colorGameStore.bars.green + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{Number(this.eventStore.currentRound.greenTotal).toLocaleString() }}
        </p>
      </div>
      <div class="progress-bar  progress-bar-animated" role="progressbar" aria-valuenow="100"
        aria-valuemin="100" :style="{ height: '55px', width: this.colorGameStore.bars.blue + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{ Number(this.eventStore.currentRound.blueTotal).toLocaleString() }}
        </p>

      </div>
      <div class="progress-bar  progress-bar-animated bg-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0"
        :style="{ height: '55px', width: this.colorGameStore.bars.yellow + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{Number(this.eventStore.currentRound.yellowTotal).toLocaleString() }}
        </p>
      </div>
      <div class="progress-bar  progress-bar-animated bg-dark" role="progressbar" aria-valuenow="100"
        aria-valuemin="100" :style="{ height: '55px', width: this.colorGameStore.bars.white + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{ Number(this.eventStore.currentRound.whiteTotal).toLocaleString() }}
        </p>

      </div>
      <div class="progress-bar  progress-bar-animated bg-pink" role="progressbar" aria-valuenow="50" aria-valuemin="0"
        :style="{ height: '55px', width: this.colorGameStore.bars.pink + '%' }">
        <p style="color:black; font-size: 25px; font-weight: bold;">{{Number(this.eventStore.currentRound.pinkTotal).toLocaleString() }}
        </p>
      </div>
    </div>
  
  

 




</template>

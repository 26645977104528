
<script>

export default {
 props: ["link"],


}


</script>



  <template>
    

    
    <div class="card">
   
      
      <!-- 16:9 aspect ratio -->
      <div class="ratio ratio-4x3">

        
          
        <iframe :src="link" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
      </div>

  </div>   
    
    
    
    
    
    
</template>
<script>
import axios from 'axios';
import { useEventStore } from '../../stores/events';

import { SimpleBar } from 'simplebar-vue3';
import { usePlayerStore } from '/src/stores/player'




export default {
    components: { SimpleBar },
    data() {
        return {

            eventStore: useEventStore(),
            playerStore: usePlayerStore(),





        }



    },



    mounted() {


    },

    methods: {





    }



}
</script>

<template>


    <div>
        <div class="card border border-info" style="min-height: 300px;">
            <div class="card-body">
                <h4 class="card-title text-info">Bet History</h4>

                <div v-if="eventStore.eventDetails.gameType == 'sabong'">
                    <SimpleBar style="max-height: 200px;">
                        <table class="table table-striped align-middle mb-0 table-sm text-center table-responsive font-size-14">
                            <thead>
                                <tr>
                                    <th> Fight</th>


                                    <th>Bet</th>


                                    <th>Choice</th>
                                    
                                    <th>Winner</th>
                                    <th>Balance</th>



<!-- 
                                    <th>Odds</th>




                                    <th>Chips</th> -->
                                </tr>
                            </thead>
                        <tbody>


                                <tr v-for="bet in playerStore.betHistory.results">

                             
                                    <!-- <td class="text-danger" v-if="bet.choice=='meron'">{{bet.roundNumber}} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{bet.roundNumber}} </td>
<td class="text-danger" v-if="bet.choice=='meron'">{{bet.amount}} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{bet.amount}} </td>

<td class="text-danger" v-if="bet.choice=='meron'">{{bet.oddsChoice}} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{bet.oddsChoice}} </td>
<td class="text-danger" v-if="bet.choice=='meron'">{{ Number(bet.finalBalance).toLocaleString() }} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{ Number(bet.finalBalance).toLocaleString() }} </td> -->
                          <td>{{ bet.roundNumber }}</td>


                                    <td>{{ bet.amount }}</td>
                                    <td class="text-danger" v-if="bet.choice == 'meron'"> M </td>
                                    <td class="text-primary" v-else-if="bet.choice == 'wala'"> W  </td>
                                    <td class="text-success" v-else-if="bet.choice == 'draw'"> D </td>
                                 
                                    <td class="text-danger" v-if="bet.winner_choice == 'meron'"> M  </td>
                                    <td class="text-primary" v-else-if="bet.winner_choice == 'wala'"> W </td>
                                    <td class="text-success" v-else-if="bet.winner_choice == 'draw'"> D </td>
                                    <td class="text-dark" v-else-if="bet.winner_choice == null && bet.finalBalance < bet.currentBalance"> N/A </td>
                                    <td class="text-dark" v-else> C </td>

                                    <td class="text-success" v-if="bet.winner_choice == bet.choice">
                                        (+{{    bet.finalBalance - bet.currentBalance }})
                                        {{ Number(bet.finalBalance).toLocaleString() }}</td>




                                        <td class="text-danger" v-else>
                                            ({{ bet.finalBalance - bet.currentBalance }})
                                        {{ Number(bet.finalBalance).toLocaleString() }}</td>
                                    
                                    <!-- <td>{{ bet.oddsChoice }}</td>
                                    <td> {{ Number(bet.finalBalance).toLocaleString() }}</td> -->

                                </tr>
                            </tbody>
                        </table>
                    </SimpleBar>
                </div>

                <div class="" v-if="eventStore.eventDetails.gameType == 'color-game'">
                    <SimpleBar style="max-height: 200px;">
                        <table class="table table-striped align-middle mb-0 table-sm text-center table-responsive font-size-14">
                            <thead>
                                <tr>
                                    <th> Round</th>


                                    <th class="text-danger">Red</th>
                                    <th class="" style="color:lightslategray">Gray</th>
                                    <th class="text-primary">Blue</th>
                                    <th class="text-warning">Yellow</th>
                                    <th class="text-dark">White</th>
                                    <th class="text-pink">Pink</th>


                                   
                                </tr>
                            </thead>
                            <tbody>


                                <tr v-for="bet in playerStore.betHistory.results">

                                    <!-- <td class="text-danger" v-if="bet.choice=='meron'">{{bet.roundNumber}} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{bet.roundNumber}} </td>
<td class="text-danger" v-if="bet.choice=='meron'">{{bet.amount}} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{bet.amount}} </td>

<td class="text-danger" v-if="bet.choice=='meron'">{{bet.oddsChoice}} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{bet.oddsChoice}} </td>
<td class="text-danger" v-if="bet.choice=='meron'">{{ Number(bet.finalBalance).toLocaleString() }} </td>
<td class="text-primary" v-if="bet.choice=='wala'">{{ Number(bet.finalBalance).toLocaleString() }} </td> -->

                                    <td>{{ bet.roundNumber }}</td>


                                    <td class="text-danger">{{ bet.red }}</td>
                                    <td class="" style="color:lightslategray">{{ bet.green }}</td>
                                    <td class="text-primary">{{ bet.blue }}</td>
                                    <td class="text-warning">{{ bet.yellow }}</td>
                                    <td class="text-dark">{{ bet.white }}</td>
                                    <td class="text-pink">{{ bet.pink }}</td>
                                

                                </tr>
                            </tbody>
                        </table>
                    </SimpleBar>
                </div>
            </div>
        </div>

    </div>
</template>
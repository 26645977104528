

<script>

import { useToast } from "vue-toastification";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { SimpleBar } from "simplebar-vue3"
import chips from "/src/components/playerUI/chips"
import FH from "/src/components/bets/fighthistory";
import BH from "/src/components/bets/bethistory";
import videoPlayerVue from "../../../components/playerUI/videoPlayer.vue";
import { useAccountStore } from '/src/stores/account'
import { useOperatorStore } from '/src/stores/operator'
import { useEventStore } from '/src/stores/events'
import { usePlayerStore } from '/src/stores/player'
import betsVue from "../../../components/playerUI/bets.vue";
import betsBarVue from "../../../components/playerUI/betsBar.vue";
import userBetsVue from "../../../components/playerUI/userBets.vue";
import declareWinnerVue from "../../../components/operator/operatorControls.vue";
import { useSabongStore } from "../../../stores/sabong";



  






export default {

  setup() {
  },

  page: {
    title: "Sweet Alert",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, SimpleBar, FH, BH, chips, videoPlayerVue, betsVue, betsBarVue, userBetsVue, declareWinnerVue },
  data() {
    return {

      accountStore: useAccountStore(),
      operatorStore: useOperatorStore(),
      eventStore: useEventStore(),
      playerStore: usePlayerStore(),
      sabongStore: useSabongStore(),
      eventId: '',



      toast: useToast(),





      meronAmount: 0,
      walaAmount: 0
    };
  },
  created(){

   

  },
  mounted() {

      this.eventStore.connectWebSocket(this.$route.params.eventId, this.$route.params.gameType)
      this.eventStore.getEventDetails(this.$route.params.eventId, this.$route.params.gameType)
   

      // this.eventStore.pusher(this.eventStore.currentRound)
     

    

  },

  beforeUnmount() {

this.eventStore.disconnectWebSocket()


  },

  methods: {




  }
};
</script>

<style>
.scroll-enter-active, .scroll-leave-active {
  transition: transform 0.5s ease;
}

.scroll-enter, .scroll-leave-to {
  transform: translateY(100%);
}

.scroll-leave-active {
  position: absolute;
}
</style>

<template>
        <div v-motion-fade >
  <Layout v-if="eventStore.eventDetails.is_active">
    <center>

  
<!-- 
    {{ eventStore.currentRound }} -->
    <center>
      <h3 v-if="!eventStore.currentRound">Event is starting!</h3>
    </center>
  

    <!-- end row -->




    


  
    <div class="row">


    <div class="col-lg-7">
        <div class="row d-flex justify-content-around text-center">

      <h3 class="border border-warning text-warning col-3 rounded" v-if="eventStore.currentRound">Fight
        {{ eventStore.currentRound.roundNumber }}</h3>
        <h3 class="border border-success text-success col-3 rounded" v-if="eventStore.currentRound.status == 'open'">Open
       </h3>
        <h3 class="border border-danger text-danger col-3 rounded" v-if="eventStore.currentRound.status == 'closed'">Closed
    </h3>
      
      </div>
      <div class="card-mid">
        <videoPlayerVue :link="eventStore.eventDetails.eventVideoLink"></videoPlayerVue>
      </div>

      


     
    </div>











    <!-- betting components -->
    <div class="col-lg-5">

        <h2 class=" border border-warning text-warning">PAYOUT BELOW 120 WILL BE CANCELLED</h2>
   
      
      <betsBarVue></betsBarVue>
      <div class="col-md-12" v-if="eventStore.eventDetails.gameType=='sabong' && this.sabongStore.meronPayout > 100" >
          <div class="d-flex justify-content-around">
            <h3 class="text-danger">Payout: {{ this.sabongStore.meronPayout}}</h3> 
           <h3 class="text-info">Payout: {{ this.sabongStore.walaPayout}}</h3> 
          
          </div>

      </div>

      <div>
      
      

      <div v-if="accountStore.role=='player'" class="row d-flex justify-content-center">
       
        
        
        <div class="col-12">
          <betsVue></betsVue>
        </div>

        <div class="col-12">
         <userBetsVue></userBetsVue>
      </div>
       
    

      

      </div>
    </div>
    
      
      <div  class="row d-flex justify-content-center">
        <div class="col-md-12" v-if="accountStore.role=='operator'">
          <declareWinnerVue></declareWinnerVue>
        </div>


      </div>
    </div>
      
   
    <div  class="row d-flex justify-content-center">
        <div class="col-md-6">

          <FH></FH>
        </div>


        <div class="col-md-6" v-if="accountStore.role=='player'">
          <BH />
        </div>

      </div>

   

    



    




    


   

  </div>
  </center>
  
  </Layout>

  <Layout v-else>
    <center>
      <h3>Event is over or does not exist!</h3>
    </center>


  </Layout>
</div>
</template>

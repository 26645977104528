

<script>

import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import { useEventStore } from '../../stores/events';
import { useOperatorStore } from '../../stores/operator';
import { useColorGameStore } from '../../stores/colorGame';


export default {




    data() {

        return {

            eventStore: useEventStore(),
            operatorStore: useOperatorStore(),
            colorGameStore: useColorGameStore()









        };


    },



}


</script>


<template>



    <b-card header-class="bg-transparent border-primary" class="border border-primary"
       >
        <template v-slot:header>
            <h5 class="my-0 text-primary text-center">
                Declare Result
            </h5>
        </template>
        <div class="row text-center">

            <!-- ---------------------------------------------- -->

            <div class="col-4">
                <p class="card-text">
                    <b-button
                        v-if="eventStore.currentRound.status == 'closed' && eventStore.currentRound.roundWinner == null"
                        variant="outline-success" class="w-sm" @click="operatorStore.roundStatus('open')">
                        <i class="bx bx-check-circle font-size-12"></i> Open Round
                    </b-button>
                    <b-button
                        v-if="eventStore.currentRound.status == 'open' && eventStore.currentRound.roundWinner == null"
                        variant="outline-danger" class="w-sm" @click="operatorStore.roundStatus('closed')">
                        <i class="bx bx-x-circle  font-size-12"></i>
                        Close Round
                    </b-button>
                </p>
                <p class="card-text d-flex flex-wrap justify-content-around" v-if="eventStore.currentRound.gameType=='color-game'">
                    <b-button
                        :disabled="eventStore.currentRound.status == 'open' || eventStore.colorGameWinners.length < 3"
                        variant="outline-info" @click="operatorStore.declareWinner(eventStore.colorGameWinners)">DECLARE
                        WINNER</b-button>
                </p>


                <p class="card-text d-flex flex-wrap justify-content-around">
                    <b-button v-if="eventStore.currentRound.roundWinner  != null || eventStore.currentRound.status == 'cancelled'" variant="outline-dark"
                        @click="operatorStore.nextFight">NEXT ROUND</b-button>
                </p>



            </div>

                   

        

            <div class="col-5" v-if="eventStore.currentRound.roundWinner == null && eventStore.eventDetails.gameType=='sabong'">

                <p class="card-text d-flex flex-wrap justify-content-around">
                    <b-button v-if="eventStore.currentRound.status=='closed'" variant="outline-danger"
                        @click="operatorStore.declareWinner('meron')">MERON</b-button>
                </p>
                <p class="card-text d-flex flex-wrap justify-content-around">
                    <b-button v-if="eventStore.currentRound.status=='closed'" variant="outline-primary"
                        @click="operatorStore.declareWinner('wala')">WALA</b-button>
                </p>
                <p class="card-text d-flex flex-wrap justify-content-around">
                    <b-button v-if="eventStore.currentRound.status=='closed'" variant="outline-warning"
                        @click="operatorStore.declareWinner('draw')">DRAW</b-button>
                </p>
                <!-- <p class="card-text d-flex flex-wrap justify-content-around">
                    <b-button v-if="eventStore.currentRound.status == 'closed'" variant="outline-dark"
                        @click="operatorStore.roundStatus('cancelled')">CANCELLED</b-button>
                </p> -->



            </div>

            <div class="row d-flex col-5" v-if="eventStore.currentRound.roundWinner == null && eventStore.eventDetails.gameType=='color-game'">

<div class="col-1">

    <fieldset class="mb-3 form-radio-outline" id="set1"  :disabled="eventStore.currentRound.status == 'open'"><!---->
        <div class="">
            <div class="form-check form-radio-danger mb-2"><input v-model="eventStore.colorGameWinners[0]" class="form-check-input" type="radio"
                    value="red"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-  mb-2"><input v-model="eventStore.colorGameWinners[0]" class="form-check-input" type="radio"
                    value="green"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-primary  mb-2"><input v-model="eventStore.colorGameWinners[0]" class="form-check-input" type="radio"
                    value="blue"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-warning  mb-2"><input v-model="eventStore.colorGameWinners[0]" class="form-check-input" type="radio"
                    value="yellow"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-dark  mb-2"><input v-model="eventStore.colorGameWinners[0]" class="form-check-input" type="radio"
                    value="white"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-pink  mb-2"><input v-model="eventStore.colorGameWinners[0]" class="form-check-input" type="radio"
                    value="pink"></div><!----><!----><!---->
        </div>
    </fieldset>
</div>
<div class="col-1">

    <fieldset class="mb-3 form-radio-outline" id="set2" :disabled="eventStore.currentRound.status == 'open'"><!---->
        <div class="">
            <div class="form-check form-radio-danger  mb-2"><input  v-model="eventStore.colorGameWinners[1]" class="form-check-input" type="radio"
                    value="red"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-  mb-2"><input v-model="eventStore.colorGameWinners[1]" class="form-check-input" type="radio"
                    value="green"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-primary  mb-2"><input v-model="eventStore.colorGameWinners[1]" class="form-check-input" type="radio"
                    value="blue"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-warning  mb-2"><input v-model="eventStore.colorGameWinners[1]" class="form-check-input" type="radio"
                    value="yellow"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-dark  mb-2"><input v-model="eventStore.colorGameWinners[1]" class="form-check-input" type="radio"
                    value="white"></div><!----><!----><!---->
        </div>
        <div class="">
            <div class="form-check form-radio-pink  mb-2"><input v-model="eventStore.colorGameWinners[1]" class="form-check-input" type="radio"
                    value="pink"></div><!----><!----><!---->
        </div>
    </fieldset>
</div>

<div class="col-2">

    <fieldset class="mb-3 form-radio-outline" id="set3" :disabled="eventStore.currentRound.status == 'open'"><!---->
        <div class="mb-0">
            <div class="form-check form-radio-danger mb-0"><input  v-model="eventStore.colorGameWinners[2]" class="form-check-input" type="radio"
                    value="red"> <label class="text-danger">
                    RED </label></div><!----><!----><!---->

        </div>
        <div class="">
            <div class="form-check form-radio- mb-0"><input  v-model="eventStore.colorGameWinners[2]" class="form-check-input" type="radio"
                    value="green"> <label class="text-">
                    GRAY </label></div><!----><!----><!---->

        </div>
        <div class="">
            <div class="form-check form-radio-primary mb-0"><input  v-model="eventStore.colorGameWinners[2]" class="form-check-input" type="radio"
                    value="blue"> <label class="text-primary">
                    BLUE </label></div><!----><!----><!---->

        </div>
        <div class="">
            <div class="form-check form-radio-warning mb-0"><input  v-model="eventStore.colorGameWinners[2]" class="form-check-input" type="radio"
                    value="yellow"> <label class="text-warning">
                    YELLOW </label></div><!----><!----><!---->

        </div>
        <div class="">
            <div class="form-check form-radio-dark mb-0"><input  v-model="eventStore.colorGameWinners[2]" class="form-check-input" type="radio"
                    value="white"> <label class="text-dark">
                    WHITE </label></div><!----><!----><!---->

        </div>
        <div class="">
            <div class="form-check form-radio-pink mb-0"><input  v-model="eventStore.colorGameWinners[2]" class="form-check-input" type="radio"
                    value="pink"> <label class="text-pink">
                    PINK </label></div><!----><!----><!---->

        </div>
        
    </fieldset>
</div>




                </div>



                <div class="col-3">

<p class="card-text d-flex flex-wrap justify-content-around">
    <b-button v-if="eventStore.currentRound.status == 'closed' || eventStore.currentRound.status == 'cancelled'" variant="outline-dark"
        @click="operatorStore.goToRound()">GO TO ROUND</b-button>
</p>


<p class="card-text d-flex flex-wrap justify-content-around">
    <b-button variant="outline-pink" v-if="eventStore.currentRound.status == 'closed'"
        @click="operatorStore.roundStatus('cancelled')"> CANCEL ROUND</b-button>
</p>




</div>
        </div>
       

    </b-card>

   
      


  
</template>






<script>

import { useEventStore } from '/src/stores/events'
import { usePlayerStore } from '/src/stores/player'
import { useAccountStore } from '/src/stores/account'


export default {




  data() {

    return {
      eventStore: useEventStore(),
      playerStore: usePlayerStore(),
      accountStore: useAccountStore(),






    };


  },

  mounted() {



  },


  methods: {





  }



}




</script>




<template>

    <div class="">


   

 <div class="row d-flex justify-content-center">




<div class="">



  <!-- new  -->
  <div class="card text-center"><!---->
    <div class="row card-header bg-transparent mt-2">
      <div class="col">  <h5 class="text-info"><i class="mdi mdi-bullseye-arrow me-3"></i>Place Your Bets!</h5></div>
    <div class="col">
      <h5 class="text-warning card-title">
       POINTS:  {{ Number(accountStore.chips).toLocaleString() }}   <i class="fa fa-solid fa-coins"></i>  
            </h5>
    

    </div>
     
    </div>
    <div class="card-body"><!----><!---->
      <div class="d-flex-row">
     
          
      <b-form-input class=" border border-info mb-3" style="width:50%; text-align:center; margin: auto;" id="forminput" type="number" oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? this.value : null"
        v-model="playerStore.bet"></b-form-input>
      <div class="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group me-2 mb-3 d-flex" role="group" aria-label="First group">
          <button type="button" class="btn btn-outline-info" @click="playerStore.bet = 20">
            20
          </button>
          <button type="button" class="btn btn-outline-info" @click="playerStore.bet = 50">
            50
          </button>
          <button type="button" class="btn btn-outline-info" @click="playerStore.bet = 100">
            100
          </button>
          <button type="button" class="btn btn-outline-info" @click="playerStore.bet = 200">
            200
          </button>
          <button type="button" class="btn btn-outline-info" @click="playerStore.bet = 500">
            500
          </button>
          <button type="button" class="btn btn-outline-info" @click="playerStore.bet = 1000">
            1000
          </button>
        </div>
      </div>

    </div>



      <div class="row d-flex"  v-if="eventStore.eventDetails.gameType == 'color-game'">
          
  <div class="d-flex flex-row bd-highlight mb-3 justify-content-around">
  <div class="d-flex-col justify-content-around">
    
    <div class="row mb-2" style="gap:8px">
    <button     :disabled="eventStore.currentRound.status != 'open'" @click="playerStore.confirmBet('color-game', playerStore.bet, 'red')" class="btn btn-danger rounded-circle rounded-sm" style="width:70px; height: 70px;"></button>
    <button     :disabled="eventStore.currentRound.status != 'open'" @click="playerStore.confirmBet('color-game', playerStore.bet, 'green')" class="btn rounded-circle" style="width:70px; height: 70px; background-color:lightslategray;"></button>
    <button     :disabled="eventStore.currentRound.status != 'open'" @click="playerStore.confirmBet('color-game', playerStore.bet, 'blue')" class="btn btn-primary rounded-circle" style="width:70px; height: 70px;"></button>
    </div>
    <div class="row" style="gap:8px">
    <button     :disabled="eventStore.currentRound.status != 'open'" @click="playerStore.confirmBet('color-game', playerStore.bet, 'yellow')" class="btn btn-warning rounded-circle" style="width:70px; height: 70px;"></button>
    <button     :disabled="eventStore.currentRound.status != 'open'" @click="playerStore.confirmBet('color-game', playerStore.bet, 'white')" class="btn btn-dark rounded-circle" style="width:70px; height: 70px;"></button>
    <button     :disabled="eventStore.currentRound.status != 'open'" @click="playerStore.confirmBet('color-game', playerStore.bet, 'pink')" class="btn btn-pink rounded-circle" style="width:70px; height: 70px;"></button>
  </div>
       
          


</div>
</div>
   
    
      </div>


      
      <div class="row d-flex" v-if="eventStore.eventDetails.gameType == 'sabong'">
                <div class="col">
                  <button type="button" class="btn btn-outline-danger "
                    :disabled="eventStore.currentRound.status != 'open'"
                    @click="playerStore.confirmBet('sabong', playerStore.bet, 'meron')">MERON</button>

                </div>
                <div class="col">
                  <button type="button" class="btn btn-outline-success"
                    :disabled="eventStore.currentRound.status != 'open'"
                    @click="playerStore.confirmBet('sabong', playerStore.bet, 'draw')">DRAW</button>
                </div>
                <div class="col">
                  <button type="button" class="btn btn-outline-primary"
                    :disabled="eventStore.currentRound.status != 'open'"
                    @click="playerStore.confirmBet('sabong', playerStore.bet, 'wala')">WALA</button>
                </div>
              </div>
    </div><!----><!----><!---->
  </div>






</div>


</div>




    </div>


 




</template>
